import React from "react";

const PopUpModal = ({ isOpen, onClose, children }) => {
  if (isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="confirmation-popup">{children}</div>
    </div>
  );
};

export default PopUpModal;
