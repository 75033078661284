import React from "react";

const BillingAddInputField = (props) => {
  return (
    <div>
      <input
        {...props.field}
        type={props?.type}
        placeholder={props?.placeholder}
        className={`
          ${props?.bookingEditInput}
          ${props?.login_input_field}
          ${props?.otherAmount}
          ${props?.newRegisterInput}
          ${props?.newRegisterInputTwo}
          ${props?.loginregisterpass}
          ${props?.newRoomInputField}
          ${props?.newRoomCount}
          ${props?.popupInputField}
        `}
        list={props?.list}
        disabled={props?.disabled}
        style={
          props?.fieldState && props?.fieldState?.error
            ? {
                borderColor: "red",
              }
            : {}
        }
      />
    </div>
  );
};

export default BillingAddInputField;
